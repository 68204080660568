import React from "react"

const Linkedin = () => {
    return (
        <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_119_57)">
                <path
                    d="M0.600098 1.32358C0.600098 0.631803 1.17494 0.071167 1.8842 0.071167H16.8017C17.511 0.071167 18.0858 0.631803 18.0858 1.32358V16.3045C18.0858 16.9962 17.511 17.5569 16.8017 17.5569H1.8842C1.17494 17.5569 0.600098 16.9962 0.600098 16.3045V1.32358ZM6.00209 14.7089V6.813H3.37814V14.7089H6.00209ZM4.69066 5.73435C5.60538 5.73435 6.17476 5.12891 6.17476 4.37047C6.15837 3.59563 5.60648 3.00658 4.70815 3.00658C3.80982 3.00658 3.22295 3.59672 3.22295 4.37047C3.22295 5.12891 3.79233 5.73435 4.67318 5.73435H4.69066ZM10.0544 14.7089V10.2992C10.0544 10.0632 10.0719 9.8271 10.1418 9.6588C10.3309 9.18778 10.7626 8.69927 11.4882 8.69927C12.4379 8.69927 12.8171 9.42275 12.8171 10.485V14.7089H15.4411V10.1801C15.4411 7.75395 14.1472 6.62612 12.4204 6.62612C11.0281 6.62612 10.4041 7.39112 10.0544 7.9299V7.95722H10.0369C10.0427 7.9481 10.0486 7.93899 10.0544 7.9299V6.813H7.43155C7.46433 7.55396 7.43155 14.7089 7.43155 14.7089H10.0544Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_119_57">
                    <rect
                        width="17.4857"
                        height="17.4857"
                        fill="currentColor"
                        transform="translate(0.600098 0.071167)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Linkedin
